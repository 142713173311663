<script lang="ts">
  import { onMount } from "svelte";
  import { isAdmin, isSuperAdmin } from "../stores/store";
  import { getNamedEntities } from "../services/cleanup";
  import Loading from "./Loading.svelte";
  import NamedRow from "./NamedRow.svelte";
  import NameFilter from "./NameFilter.svelte";

  export let projectId;
  let nameData;
  let filteredData;
  let currentFilter = "";
  let dangerEnabled = false;
  $: {
    if (nameData) {
      filteredData = nameData.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(currentFilter.toLowerCase()) > -1
        );
      });
    
   filteredData.sort((a, b) => {
         let fa = a.name.toLowerCase();
         let fb = b.name.toLowerCase();
         if(fa === fb){
             return 0;
         }
         //dealing with empty so they arent at the top these at the only variations i see
         if(fa === ' ' || fa === '' || fa === null || fa === undefined || fa ===  '  '){
             return 1;
         }

         if(fb === ' ' || fb === '' || fb === null || fb === undefined || fb ===  '  '){
             return -1;
         }

         if(fa < fb) {
             return -1;
         }


         if(fa > fb){
             return 1;
         }

     });
     console.log(filteredData);
    }
  }
  const loadData = async () => {
    nameData = await getNamedEntities(projectId, "all", ["org", "person"]);
    console.log(nameData);
  };
  onMount(async () => {
    await loadData();
  });
</script>

<div class="panel" class:show-danger={dangerEnabled}>
  {#if nameData && nameData}
    <NameFilter bind:value={currentFilter} />
    <table class="tight-input">
      <thead>
        {#if $isAdmin}
        <th class="is-narrow" />
        <th width="50%">Current entry</th>
        <th colspan="2" width="50%">New entry</th>
        <th
          class="is-danger"
          colspan="2"
          on:click={() => {
            dangerEnabled = !dangerEnabled;
          }}>Danger zone</th
        >
        {:else}
         <th width="50%">Organisations</th>
        {/if}
      </thead>
      <tbody>
        {#each filteredData as c, index (c.id)}
          <NamedRow {projectId} data={c} removed={loadData} />
        {/each}
      </tbody>
    </table>
  {:else}
    <Loading />
  {/if}
</div>

<style lang="scss">
  button {
    line-height: 18px;
    font-weight: normal;
  }
  th.is-danger {
    width: 160px;
  }
</style>
