<script lang="ts">
  import { onMount } from "svelte";
  import { dataset_dev } from "svelte/internal";
  import { getDataForCleanup, allErrorData } from "../services/cleanup";
  import { getProject } from "../services/project";
  import { isAdmin, isSuperAdmin } from "../stores/store";
  import { readable_question } from "../utils/readable_question";
  import Loading from "./Loading.svelte";
  import QuestionInsertRow from "./QuestionInsertRow.svelte";
  export let projectId;
  let cleanupData;
  let filteredData;
  let project;
  let dangerEnabled = false;
  let dataStrings = [];


  const loadData = async () => {
    project = await getProject(projectId);
    cleanupData = await getDataForCleanup(projectId);
   
    filteredData = await allErrorData(cleanupData,  project.questionSet.questions);
    console.log('error filtered data', filteredData);
  };

  const updated = async () => {
    await loadData();
  };
  onMount(async () => {
    await loadData();
  });

</script>

<div class="panel" class:show-danger={dangerEnabled}>
  {#if filteredData}
  {#if filteredData.length >= 1}
    <table class="tight-input">
      <thead>
        <th class="is-narrow" />
        <th class="is-narrow" />
        <th width="50%">Current</th>
        <th width="50%">Entry</th>
        <th class="is-narrow" />
     
      </thead>
      <tbody>
          {#key filteredData}
        {#each filteredData as c, index (index)}
          {#each c.data as item, innerIndex}
            <QuestionInsertRow

              finalRowInBlock={innerIndex + 1 >= c.data.length}
              {item}
              user={c.user}
              {projectId}
              
              {updated}
            
            />

          {/each}
        {/each}
        {/key}
      </tbody>
    </table>
    {:else}
        <h1>The data is all in the correct format</h1>
    {/if}
  {:else}
    <Loading />
  {/if}
</div>

<style lang="scss">
</style>
